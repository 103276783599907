.container-title-btn {
  color: white;
}

.title-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  margin: 0 auto;
  overflow: auto;
}

.iconLabel {
  color: white;
}

@media (max-width: 600px) {
  .title-btn {
    width: 80vw;
  }
}

@media (min-width: 600px) {
  .title-btn {
    width: 90vw;
  }
}
