.container-photo {
  display: flex;
  display: inline;
  align-items: center;
  justify-content: center;
}
.container-photo .container-btn-photo {
  width: 100%;
  height: 35vh;
  margin: 6px;
  background: #0c1e3b;
  font-size: 11px;
  color: #9cafc3;
  border: 1px dashed #9cafc3;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
.text-img {
  color: #3e4afb;
  text-decoration: underline;
}
.icon-photo {
  color: #9cafc3;
}

.box {
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 44vh;
  min-height: 24vh;
  overflow: hidden;
}

.box img {
  width: 100%;
}

@supports (object-fit: cover) {
  .box img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media (min-width: 600px) {
  .container-photo .container-btn-photo {
    height: 35vh;
    min-height: 35vh;
  }
  .box {
    height: 34vh;
  }
}

@media (max-width: 600px) {
  .container-photo .container-btn-photo {
    min-height: 35vh;
  }
  .box {
    height: 34vh;
  }
}
