.root {
  max-width: 405px;
  min-width: 405px;
  margin: 10px;
}

.image {
  max-width: 385px;
  border-radius: 20px;
  padding: 10px 10px 0px 10px;
}

@media (max-width: 864px) {
  .root {
    max-width: 395px;
    min-width: 395px;
  }
  .image {
    max-width: 375px;
  }
}

@media (max-width: 845px) {
  .root {
    max-width: 355px;
    min-width: 355px;
  }
  .image {
    max-width: 335px;
  }
}

@media (max-width: 763px) {
  .root {
    max-width: 315px;
    min-width: 315px;
  }
  .image {
    max-width: 295px;
  }
}

@media (max-width: 682px) {
  .root {
    max-width: 290px;
    min-width: 290px;
  }
  .image {
    max-width: 255px;
  }
}

@media (max-width: 635px) {
  .root {
    max-width: 275px;
    min-width: 275px;
  }
  .image {
    max-width: 255px;
  }
}

@media (max-width: 600px) {
  .root {
    max-width: 555px;
    min-width: 555px;
  }
  .image {
    max-width: 535px;
  }
}

@media (max-width: 500px) {
  .root {
    max-width: 465px;
    min-width: 465px;
  }
  .image {
    max-width: 450px;
  }
}

@media (max-width: 550px) {
  .root {
    max-width: 435px;
    min-width: 435px;
  }
  .image {
    max-width: 415px;
  }
}

@media (max-width: 500px) {
  .root {
    max-width: 415px;
    min-width: 415px;
  }
  .image {
    max-width: 395px;
  }
}

@media (max-width: 450px) {
  .root {
    max-width: 385px;
    min-width: 385px;
  }
  .image {
    max-width: 355px;
  }
}

@media (max-width: 405px) {
  .root {
    max-width: 375px;
    min-width: 375px;
  }
  .image {
    max-width: 345px;
  }
}

@media (max-width: 660px) {
  .root {
    max-width: 340px;
    min-width: 340px;
  }
  .image {
    max-width: 325px;
  }
}

@media (max-width: 355px) {
  .root {
    max-width: 335px;
    min-width: 335px;
  }
  .image {
    max-width: 315px;
  }
}

@media (max-width: 320px) {
  .root {
    max-width: 300px;
    min-width: 300px;
  }
  .image {
    max-width: 275px;
  }
}
