.nav-lower {
  overflow: hidden;
  position: relative;
  bottom: -50px;
  width: 100%;
}
.container-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 15px 14px 15px;
}
.btn-crear {
  color: #d4f123;
  width: 100%;
}

.btn-crear:hover {
  background: linear-gradient(135deg, #d4f123 1.94%, #37f8a2 100%);
}
